<template>
  <div
    :class="{
      PrimaryOutline: true,
      noselect: true,
      Disabled: !isActive,
      ...getSizeStyle,
    }"
    @click="clickEvent"
  >
    <slot name="LeftIcon"></slot>

    <span class="TText" lang="ru">{{ ButtonText }}</span>

    <slot name="RightIcon"></slot>
  </div>
</template>

<script>
/*
    Если вставляются какие-то иконки, им нужно прописать отдельно стили для цвета при разных состояниях
      
    .Tertary:not(.Disabled):active > .YourIconClass {
        color/fill: rgba(101, 107, 255, 1);
    }
 
    .Tertary.Disabled > .YourIconClass {
        color/fill: rgba(211, 202, 255, 0.3);
    }
*/
export default {
  name: "OUITertaryButton",
  props: {
    ButtonText: {
      type: String,
      default: () => {
        return "";
      },
    },
    isActive: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    SendCallback: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
    size: {
      type: String,
      default: "small",
    },
  },
  methods: {
    clickEvent() {
      if (this.isActive) {
        this.SendCallback();
      }
    },
  },
  computed: {
    getSizeStyle() {
      let styles = ["small", "medium"];
      if (styles.includes(this.size)) {
        let obj = {};
        obj[this.size] = true;
        return obj;
      }

      return { small: true };
    },
  },
};
</script>

<style scoped>
.PrimaryOutline.medium {
  padding: 14px 24px;
}
.medium > .TText {
  font-size: 16px;
}

.PrimaryOutline {
  position: relative;
  display: flex;

  cursor: pointer;

  padding: 8px;

  min-width: 0;
  width: fit-content;
  max-width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;

  border-radius: 4px;
  border: 0px solid;
  backdrop-filter: blur(1px);

  transition: 0.2s;
  overflow: hidden;
}
.PrimaryOutline::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 4px;
  padding: 1px;
  background: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  transition: 0.2s;
}
.PrimaryOutline > *:not(.BGIMG) {
  z-index: 2;
}
.BGIMG {
  position: absolute;
  display: block;

  inset: 0;

  width: 100%;
  height: 100%;

  background: linear-gradient(246.04deg, #ec4bc9 15.39%, #656bff 84.61%);

  z-index: 1;

  transition: filter 0.2s;
  filter: brightness(1);
}
.PrimaryOutline > * {
  margin-top: auto;
  margin-bottom: auto;
}
.TText {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  hyphens: auto;
  word-break: break-all;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;

  color: rgba(255, 255, 255, 0.9);
}

.PrimaryOutline:not(.Disabled):hover .BGIMG {
  filter: brightness(0.8);
}

.PrimaryOutline:not(.Disabled):hover::before {
  filter: brightness(0.8);
}

.PrimaryOutline:not(.Disabled):active .BGIMG {
  filter: brightness(0.6);
}
.PrimaryOutline:not(.Disabled):active > .TText {
  color: rgb(223, 224, 255);
}

.PrimaryOutline.Disabled {
  cursor: default;
}
.PrimaryOutline.Disabled::before {
  opacity: 0.5;
}
.PrimaryOutline.Disabled .BGIMG {
  filter: brightness(1);
  background: linear-gradient(
    246.04deg,
    rgba(233, 36, 191, 0.4) 15.39%,
    rgba(81, 41, 245, 0.4) 84.61%
  );
}
.PrimaryOutline.Disabled > .TText {
  color: rgba(211, 202, 255, 0.3);
}

.PrimaryOutline.Disabled.AnimationIfNotActive {
  background: linear-gradient(130deg, #000, rgb(99, 52, 230), #000);
  background-size: auto;
  background-size: auto;
  background-size: 200% 200%;

  animation: notActiveAnim 3s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
}

@keyframes notActiveAnim {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
</style>
